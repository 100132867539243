//Libraries
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Alert as MUIAlert } from '@mui/material';
import IntlMessages from 'components/util/IntlMessages';

// icons
import { ReactComponent as SuccessIcon } from 'assets/@efz/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/@efz/icons/warning.svg';
import { ReactComponent as InfoIcon } from 'assets/@efz/icons/info.svg';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { AlertTitle } from '@mui/material';

const Alert = ({
    severity,
    className,
    IntlMessages,
    secondaryText
}) => (
    <MUIAlert
        severity={severity}
        className={`efz-alert-${severity} ${className}`}
        iconMapping={{
            success: <SuccessIcon />,
            warning: <WarningIcon />,
            info: <InfoIcon />,
        }}
    >
        {isFieldDefined(secondaryText) ?
            <AlertTitle className='efz-alert-title'>{IntlMessages}</AlertTitle> :
            IntlMessages
        }
        {secondaryText}
    </MUIAlert>
)

//defaultProps
Alert.defaultProps = {
    severity: 'info',
    IntlMessages: <IntlMessages id='efz.text.loremIpsum' />,
    className: '',
    secondaryText: null,
};

// PropTypes
Alert.propTypes = {
    severity: PropTypes.string.isRequired,
    IntlMessages: PropTypes.element.isRequired,
    className: PropTypes.string.isRequired,
    secondaryText: PropTypes.node,
};

export default memo(Alert);
